import React, { Component } from 'react';
import './Options.scss';
import numeral from 'numeral';

class Options extends Component {

	constructor(props) {
		super(props);
		this.state = {
			diamondShape: 'round',
			band: 'round',
			metal: 'gold',
			polish: 'glossy',
			bandWidth: 0,
			diamondSize: 1,
			engraving: '',

			selectedOption: 'metal',
			open: false,
			diamondPrice: 332,
			bandPrice: 442,
			reviewOpen: false
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleMenuTabButtonClick = this.handleMenuTabButtonClick.bind(this);
		this.handleGripClick = this.handleGripClick.bind(this);
		this.handleReviewClick = this.handleReviewClick.bind(this);
		this.getPrices();
	}

	componentDidMount(){
		this.props.onMount(this.state);
	}

	handleInputChange(event) {
		event.stopPropagation();

		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		
		let change = { [event.target.name]: value };
		this.setState(change, function(){
			this.getPrices();
			this.props.onChange(this.state, change);
		});
	}

	handleMenuTabButtonClick(event) {
		event.stopPropagation();

		this.setState({ selectedOption: event.currentTarget.getAttribute('data-select'), open: true });
	}

	handleGripClick(event) {
		this.setState({ open: !this.state.open });
	}

	handleReviewClick(event) {
		this.setState({ reviewOpen: !this.state.reviewOpen });
	}

	getPrices() {
		//ajax call here to price api
		this.setState({ diamondPrice: (Math.random()*1000)+1000, bandPrice: (Math.random()*1000)+1000});
	}

	render() {
		return (
			<div id="Options">
				<span className="grip-btn" onClick={this.handleGripClick}></span>
				<div className="menu-tabs">
					<ul>
						{/* <li><button onClick={this.handleMenuTabButtonClick} data-select="band" className={ this.state.selectedOption === "band" ? 'open' : '' }>Band Style</button></li> */}
						<li><button onClick={this.handleMenuTabButtonClick} data-select="diamondShape" className={ this.state.selectedOption === "diamondShape" ? 'open' : '' }><i className="icon icon--diamond-shape"></i>Diamond Shape</button></li>
						<li><button onClick={this.handleMenuTabButtonClick} data-select="metal" className={ this.state.selectedOption === "metal" ? 'open' : '' }><i className="icon icon--metal"></i>Metal</button></li>
						<li><button onClick={this.handleMenuTabButtonClick} data-select="polish" className={ this.state.selectedOption === "polish" ? 'open' : '' }><i className="icon icon--polish"></i>Polish</button></li>
						<li><button onClick={this.handleMenuTabButtonClick} data-select="bandWidth" className={ this.state.selectedOption === "bandWidth" ? 'open' : '' }><i className="icon icon--band-width"></i>Band Width</button></li>
						<li><button onClick={this.handleMenuTabButtonClick} data-select="diamondSize" className={ this.state.selectedOption === "diamondSize" ? 'open' : '' }><i className="icon icon--diamond-size"></i>Diamond Size</button></li>
						<li><button onClick={this.handleMenuTabButtonClick} data-select="engraving" className={ this.state.selectedOption === "engraving" ? 'open' : '' }><i className="icon icon--engraving"></i>Engraving</button></li>
					</ul>
				</div>

				<form onChange={this.handleInputChange} className={this.state.open ? 'open' : '' }>
					<fieldset className={ this.state.selectedOption === "band" ? 'open' : '' }>
						<legend>Band Style</legend>
						<div className="select">
							<label><input type="radio" name="band" value="round" checked={this.state.band === "round"} /> Round</label>
							<label><input type="radio" name="band" value="pave" checked={this.state.band === "pave"} /> Pave</label>
							<label><input type="radio" name="band" value="twisted" checked={this.state.band === "twisted"} /> Twisted</label>
						</div>
					</fieldset>

					<fieldset className={ this.state.selectedOption === "diamondShape" ? 'open' : '' }>
						<legend>Diamond Shape</legend>
						<div className="select">
							<label><input type="radio" name="diamondShape" value="round" checked={this.state.diamondShape === "round"} /> Round</label>
							<label><input type="radio" name="diamondShape" value="oval" checked={this.state.diamondShape === "oval"} /> Oval</label>
						</div>
					</fieldset>

					<fieldset className={ this.state.selectedOption === "metal" ? 'open' : '' }>
						<legend>Metal</legend>
						<div className="select">
							<label><input type="radio" name="metal" value="gold" checked={this.state.metal === "gold"} />Gold</label>
							<label><input type="radio" name="metal" value="silver" checked={this.state.metal === "silver"} />Silver</label>
							<label><input type="radio" name="metal" value="chrome" checked={this.state.metal === "chrome"} />Chrome</label>
						</div>
					</fieldset>

					
					<fieldset className={ this.state.selectedOption === "polish" ? 'open' : '' }>
						<legend>Polish</legend>
						<div className="select">
							<label><input type="radio" name="polish" value="glossy" checked={this.state.polish === "glossy"} />Glossy</label>
							<label><input type="radio" name="polish" value="matte" checked={this.state.polish === "matte"} />Matte</label>
						</div>
					</fieldset>

					<fieldset className={ this.state.selectedOption === "bandWidth" ? 'open' : '' }>
						<legend>Band Width (cm)</legend>
						<div className="select">
							<input type="range" orient="vertical" name="bandWidth" min="0.0" max="1" step="0.5" value={this.state.bandWidth} />
							<div className="slider-value">{this.state.bandWidth}</div>
						</div>
					</fieldset>

					<fieldset className={ this.state.selectedOption === "diamondSize" ? 'open' : '' }>
						<legend>Diamond Size (cm)</legend>
						<div className="select">
							<input type="range" name="diamondSize" min="0.5" max="1.5" step="0.5" value={this.state.diamondSize} />
							<div className="slider-value">{this.state.diamondSize}</div>
						</div>
					</fieldset>

					<fieldset className={ this.state.selectedOption === "engraving" ? 'open' : '' }>
						<legend>Engraving</legend>
						<div className="select">
							<input type="text" name="engraving" />
						</div>
					</fieldset>
				</form>
				<div className="price">
					<div className="total">{numeral(this.state.diamondPrice + this.state.bandPrice).format('$0,0.00')}</div>
					<button className="review-btn" onClick={this.handleReviewClick}>Review</button>

					<div className={'review ' + (this.state.reviewOpen ? 'open' : '')}>
						<div className="detail diamond-price">
							<span className="name">Diamond</span>
							<span className="number">{numeral(this.state.diamondPrice).format('$0,0.00')}</span>
							<span className="label">{this.state.diamondShape}</span>
						</div>
						<div className="detail ring-price">
							<span className="name">Ring</span>
							<span className="number">{numeral(this.state.bandPrice).format('$0,0.00')}</span>
							<span className="label">{this.state.band}</span>
						</div>
						<div className="detail total-price">
							<span className="name">Total</span>
							<span className="number">{numeral(this.state.diamondPrice + this.state.bandPrice).format('$0,0.00')}</span>
						</div>
						<button className="close-btn" onClick={this.handleReviewClick}></button>
					</div>
				</div>
			</div>
		);
	}
}

export default Options;
