import ResizeObserver from 'resize-observer-polyfill';

class Verge3DApp {

  constructor(sceneURL, initOptions){
    this.app = null;
    this.materials = { gold: null, gold_matte: null, silver: null, silver_matte: null, chrome: null, chrome_matte: null };
    this.diamonds = { round: null, oval: null };
    this.scenes = { band: null, prong: null };
    this.locations = { diamond: null, shadow: null };
    this.band = null;
    this.shadow = null;
    
    this.loadInitialScene(sceneURL, initOptions);
  }

  loadInitialScene(sceneURL, initOptions){
    
    let ctxSettings = {};
    if (initOptions.useBkgTransp) ctxSettings.alpha = true;
    if (initOptions.preserveDrawBuf) ctxSettings.preserveDrawingBuffer = true;

    var preloader = initOptions.useCustomPreloader 
            ? this.createCustomPreloader(initOptions.preloaderProgressCb, 
            initOptions.preloaderEndCb) 
            : new window.v3d.SimplePreloader({ container: initOptions.container });
    
    this.app = new window.v3d.App(initOptions.container, ctxSettings, preloader);
    if (initOptions.useBkgTransp) {
        this.app.clearBkgOnLoad = true;
        this.app.renderer.setClearColor(0x000000, 0);
    }
    
    if (initOptions.preloaderStartCb) initOptions.preloaderStartCb();

    sceneURL = initOptions.useCompAssets ? sceneURL + '.xz' : sceneURL;
    this.app.load(sceneURL, () => {
        this.app.enableControls();
        this.app.controls.enablePan = false;
        //app.controls.enableZoom = false;
        this.app.controls.minDistance = 40;
        this.app.controls.maxDistance = 80;
        this.app.run();

        if (window.v3d.PE) window.v3d.PE.updateAppInstance(this.app);
        if (window.v3d.PL) window.v3d.PL.init(this.app, initOptions);

        this.initialSceneDidLoad(initOptions.state);
    }, () => {
        console.log('Can\'t load the scene ' + sceneURL);
    }, false);
  }

  initialSceneDidLoad(state) {
    console.log(this.app);

    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
          const {width, height} = entry.contentRect;

          this.app.renderer.setSize(width, height);
          this.app.camera.aspect = width / height;
          this.app.camera.updateProjectionMatrix();
      }
    });
     
    ro.observe(this.app.container);

    this.app.scene.background = null;
    this.materials.gold = this.app.scene.getObjectByName('material_gold').material;
    this.materials.gold_matte = this.app.scene.getObjectByName('material_gold_matte').material;
    this.materials.silver = this.app.scene.getObjectByName('material_silver').material;
    this.materials.silver_matte = this.app.scene.getObjectByName('material_silver_matte').material;
    this.materials.chrome = this.app.scene.getObjectByName('material_chrome').material;
    this.materials.chrome_matte = this.app.scene.getObjectByName('material_chrome_matte').material;
    
    this.diamonds.round = this.app.scene.getObjectByName('diamond_round');
    this.diamonds.round.visible = false;
    this.diamonds.round.disableChildRendering = true;
    this.diamonds.oval = this.app.scene.getObjectByName('diamond_oval');
    this.diamonds.oval.visible = false;
    this.diamonds.oval.disableChildRendering = true;

    this.shadow = this.app.scene.getObjectByName('shadow');
    this.shadow.visible = false;

    this.update(state);
  }

  createCustomPreloader(updateCb, finishCb) {
    function CustomPreloader() { 
        window.v3d.Preloader.call(this); 
    }

    CustomPreloader.prototype = Object.assign(Object.create(window.v3d.Preloader.prototype), {
        onUpdate: function(percentage) { 
            window.v3d.Preloader.prototype.onUpdate.call(this, percentage);
            if (updateCb) updateCb(percentage);
        },
        onFinish: function() {
            window.v3d.Preloader.prototype.onFinish.call(this);
            if (finishCb) finishCb();
        }
    });
        
    return new CustomPreloader();
  }

  setBand(state) {
    const loader = new window.v3d.GLTFLoader();

    loader.load('./scenes/ring-low-poly-shapekeys.gltf', (gltf) => {
        if(this.scenes.band){
          this.app.scene.remove(this.scenes.band);
        }

        this.app.scene.add(gltf.scene);
        this.scenes.band = gltf.scene;
        this.band = gltf.scene.getObjectByName('geometry');
        this.setMaterial(state);
        this.setBandWidth(state);
    } );
  }

  setMaterial(state) {
    if(state.polish === "matte"){
      this.band.material = this.materials[state.metal+"_"+state.polish];
    }else{
      this.band.material = this.materials[state.metal];
    }

    this.band.material.morphTargets = true;
    this.band.material.morphNormals = true;
  }

  setDiamondSize(state) {
    this.diamonds[state.diamondShape].scale.set(state.diamondSize, state.diamondSize, state.diamondSize);
  }

  setDiamondPosition(state){
    this.diamonds[state.diamondShape].position.set(this.locations.diamond.position.x, this.locations.diamond.position.y, this.locations.diamond.position.z);
    this.diamonds[state.diamondShape].visible = true;
    this.diamonds[state.diamondShape].disableChildRendering = false;
  }

  setDiamondShape(state){
    for(let key in this.diamonds){
      if(key !== state.diamondShape){ 
        this.diamonds[key].visible = false;
        this.diamonds[key].disableChildRendering = true;
      }
    }

    this.setDiamondPosition(state);
  }

  setBandWidth(state) {
    if(this.band.morphTargetInfluences){
      this.band.morphTargetInfluences[0] = parseFloat(state.bandWidth);

      this.locations.diamond = this.scenes.band.getObjectByName('location_diamond_'+state.bandWidth);
      this.locations.shadow = this.scenes.band.getObjectByName('location_shadow_'+state.bandWidth);
    }else{
      this.locations.diamond = this.scenes.band.getObjectByName('location_diamond');
      this.locations.shadow = this.scenes.band.getObjectByName('location_shadow');
    }

    if(this.locations.diamond.position.z === -0){ this.locations.diamond.position.z = 0; }
    if(this.locations.shadow.position.z === -0){ this.locations.shadow.position.z = 0; }

    this.setDiamondPosition(state);

    this.shadow.position.set(this.locations.shadow.position.x, this.locations.shadow.position.y, this.locations.shadow.position.z);
    this.shadow.visible = true;
  }

  setEngraving(state){
    let that = this;
    const canvas = document.createElement("canvas", {alpha: false});
    canvas.height = 2048;
    canvas.width = 2048;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.font = "140px Snell Roundhand, cursive";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    ctx.fillText(state.engraving, canvas.width/2, canvas.height/2);

    canvas.toBlob(function(blob){
      const url = URL.createObjectURL(blob);
      const image = new Image();
      image.src = url;
      image.onload = function(){
        //that.band.material.nodeTextures[0].img = image;
        that.band.material.nodeTextures.texture_par_tex_idx0.image = image;
        that.band.material.nodeTextures.texture_par_tex_idx0.needsUpdate = true;
      };
    });
  }

  update(state, change) {
    console.log('update:', change);

    if(change){
      if(change.band){ this.setBand(state); }
      if(change.diamondShape){ this.setDiamondShape(state); }
      if(change.metal || change.polish){ this.setMaterial(state); }
      if(change.diamondSize){ this.setDiamondSize(state); }
      if(change.bandWidth){ this.setBandWidth(state); }
      if(change.engraving){ this.setEngraving(state); }
    }else{
      this.setBand(state);
      this.setDiamondShape(state);
    }
  }

}

export default Verge3DApp;