import React, { Component } from "react";
import Verge3DApp from "./Verge3DApp.js";
import Options from "./Options";
import "./Viewport.scss";

class Viewport extends Component {
  constructor(props) {
    super(props);

    this.verge3DApp = null;
    this.optionsDidMount = this.optionsDidMount.bind(this);
    this.handleOptionsChange = this.handleOptionsChange.bind(this);
  }

  optionsDidMount(state){
    this.verge3DApp = new Verge3DApp("./scenes/main.gltf", 
      { 
        useFullscreen: false, 
        useBkgTransp: true, 
        container: "Verge3DApp",
        state: state
      });
  }

  handleOptionsChange(state, change){
    this.verge3DApp.update(state, change);
  }

  render() {
    return (
      <div id="Viewport">
        <div id="Verge3DApp" />
        <Options onChange={this.handleOptionsChange} onMount={this.optionsDidMount} />
      </div>
    );
  }
}

export default Viewport;
